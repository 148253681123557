import About from './components/About.js';
import Contact from './components/Contact.js';
import Footer from './components/Footer.js';
import Hero from './components/Hero.js';
import NavBar from './components/NavBar.js'
import Pricing from './components/Pricing.js';
import Services from './components/Services.js';

function App() {
  return (
    <>
    <NavBar />
    <Hero />
    <About />
    <Services />
    <Pricing />
    <Contact />
    <Footer />
    </>
  );
}

export default App;

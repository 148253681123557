import React from "react";
import Typewriter from "typewriter-effect";

const TypeWriter = () => {
  return (
    <>
      <Typewriter
        options={{
          autoStart: true,
          loop: true,
        }}
        onInit={(typewriter) => {
          typewriter

            .typeString("Website Design")

            .pauseFor(1500)
            .deleteChars(6)
            .typeString("Development")

            .pauseFor(1500)
            .deleteChars(11)
            .typeString("Auditing")

            .pauseFor(1500)
            .deleteChars(8)
            .typeString("Deployment")

            .pauseFor(1500)
            .deleteAll()
            .typeString("& Professional Branding")
            .pauseFor(1500)
            .start();
        }}
      />
    </>
  );
};

export default TypeWriter;
import React from 'react'

import { CodeIcon, ServerIcon } from '@heroicons/react/outline'
import { UploadIcon, CameraIcon } from '@heroicons/react/solid'

import ServicesImg from '../assets/Valley-dev-react-wallpaper.png'

const Services = () => {
  return (
    <div name='services' className='w-full mt-24 bg-zinc-200'>
      <div className='w-full h-[750px] bg-gray-900/90 absolute'>
        <img className='w-full h-full object-cover mix-blend-overlay' src={ServicesImg} alt="/" />
      </div>
      
      <div className='max-w-[1240px] mx-auto text-white relative pb-6'>
          <div className='px-4 py-12'>
              <h3 className='text-5xl font-bold py-6 text-center'>Our Services</h3>
              <p className='py-4 text-3xl text-slate-300 text-center'>Design | Development | Branding | Deployment </p>
          </div>

          <div name="services-panel-1" className='grid grid-cols-1 min-[1053px]:grid-cols-4 mi relative gap-x-8 gap-y-16 px-4 pt-12 sm:pt-20 text-black'>
              <div className='bg-white rounded-xl shadow-2xl'>
                  <div className='p-8'>
                      <CodeIcon className='w-16 p-4 bg-blue-600 text-white rounded-lg mt-[-4rem]' />
                      <h3 className='font-bold text-2xl my-6 text-blue-600'>Design & Deployment</h3>
                      <p className='text-gray-600 text-xl'>Simple single-page websites or a full-on E-commerce storefront, we have you covered.</p>
                  </div>
              </div>
              <div name="services-panel-2" className='bg-white rounded-xl shadow-2xl'>
                  <div className='p-8'>
                      <UploadIcon className='w-16 p-4 bg-blue-600 text-white rounded-lg mt-[-4rem]' />
                      <h3 className='font-bold text-2xl my-6 text-blue-600'>Auditing & Updating</h3>
                      <p className='text-gray-600 text-xl'>Ready to update your website and make it run faster & smoother? Our team can help you accomplish this quickly & easily.</p>
                  </div>
              </div>
              <div name="services-panel-3" className='bg-white rounded-xl shadow-2xl'>
                  <div className='p-8'>
                      <CameraIcon className='w-16 p-4 bg-blue-600 text-white rounded-lg mt-[-4rem]' />
                      <h3 className='font-bold text-2xl my-6 text-blue-600'>Branding & Marketing</h3>
                      <p className='text-gray-600 text-xl'>Logos, <i title='Clients in the Ottawa Valley Region'>Professional Photography*,</i> Search Engine Optimization and more.</p>
                  </div>
              </div>
              <div name="services-panel-4" className='bg-white rounded-xl shadow-2xl'>
                  <div className='p-8'>
                      <ServerIcon className='w-16 p-4 bg-blue-600 text-white rounded-lg mt-[-4rem]' />
                      <h3 className='font-bold text-2xl my-6 text-blue-600'>Hosting & Domains</h3>
                      <p className='text-gray-600 text-xl'>Providing Hosting & Domain Solutions for an easy &amp; automated process.</p>
                  </div>
              </div>
          </div>
      </div>
  </div>
  )
}

export default Services
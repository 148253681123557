import React, {useState} from "react";
import { Link, animateScroll as scroll, } from 'react-scroll'

import { MenuIcon, XIcon } from "@heroicons/react/outline";

const NavBar = () => {
    const [nav, setNav] = useState(false)
    const handleClick = () => setNav(!nav)
    const handleClose = () => setNav(!nav)

const [color, setColor] = useState(false)
    const changeColor = () => {
        if(window.scrollY >= 100) {
            setColor(true) 
        } else {
             setColor(false)
        }
    }
 
    window.addEventListener('scroll', changeColor)



  return (
    <div className="w-screen z-10 bg-zinc-200 bg-opacity-80 fixed drop-shadow-lg ">
      <div className={color ? ' bg-zinc-900 transition duration-1000 ease-in-out text-white h-[80px]' : 'transition duration-1000 ease-in-out h-[80px]' }>
      <div className="px-2 flex justify-between items-center w-full h-full">
        <div className="flex items-center">
            <Link to="home" smooth={true} duration={500} className="font-['Cinzel_Decorative'] text-2xl px-4 ">V D</Link>
          
          <ul className='hidden font-bold md:flex'>
          <li className="hover:underline"><Link to="home" smooth={true} duration={500}>Home</Link></li>
          <li className="hover:underline"><Link to="about" smooth={true} offset={-120} duration={500}>About</Link></li>
          <li className="hover:underline"><Link to="services" smooth={true} offset={-50} duration={500}>Services</Link></li>
          <li className="hover:underline"><Link to="pricing"  smooth={true} offset={-35} duration={500}>Pricing</Link></li>
          <li className="hover:underline"><Link to="contact" smooth={true} offset={-50} duration={500}>Contact</Link></li>
          </ul>
        </div>
        <div className="md:hidden mr-4" onClick={handleClick}>
            {!nav ? <MenuIcon className="w-6"/> : <XIcon className="w-5" />}
            
        </div>
      </div>
        </div>
        <ul className={!nav ? 'hidden' : 'absolute bg-zinc-200 w-full px-8'}>
          <li className="font-bold border-b-2 border-zinc-300 w-full"><Link onClick={handleClose} to="home" smooth={true} duration={500}>Home</Link></li>
          <li className="font-bold border-b-2 border-zinc-300 w-full"><Link onClick={handleClose} to="about" smooth={true} offset={-120} duration={500}>About</Link></li>
          <li className="font-bold border-b-2 border-zinc-300 w-full"><Link onClick={handleClose} to="services" smooth={true} offset={-50} duration={500}>Services</Link></li>
          <li className="font-bold border-b-2 border-zinc-300 w-full"><Link onClick={handleClose} to="pricing" smooth={true} offset={-100} duration={500}>Pricing</Link></li>
          <li className="font-bold border-b-2 border-zinc-300 w-full"><Link onClick={handleClose} to="contact" smooth={true} offset={-50} duration={500}>Contact</Link></li>
        </ul>

    </div>
  );
};

export default NavBar;

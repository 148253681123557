import React from "react";
import { Link, animateScroll as scroll, } from 'react-scroll'
import {
  CameraIcon,
  CodeIcon,
  PaperAirplaneIcon,
  ServerIcon,
} from "@heroicons/react/solid";
import bgImg from "../assets/Valley-dev-react-photo.png";
import TypeWriter from "./TypeWriter";

const Hero = () => {
  return (
    <div
      name="home"
      className="w-full h-screen bg-zinc-200 flex flex-col justify-between"
    >
      <div className="grid md:grid-cols-2 max-w-[1240px] m-auto">
        <div className="flex flex-col justify-center md:items-start w-full px-2 py-8">
          <h1 className="py-3 text-5xl md:text-7xl font-bold font-['Cinzel_Decorative'] ">Valley dev</h1>
          <div className="text-3xl text-blue-600 font-medium pl-3"><TypeWriter /></div>
        </div>
        <div>
          <img className="w-[500px] h-auto" src={bgImg} alt="Coding Wallpaper" />
        </div>
        <div className=" absolute flex flex-col py-8 md:min-w-[760px] bottom-[5%] mx-1 md:left-1/2 transform md:-translate-x-1/2 bg-zinc-200 border border-slate-300 rounded-xl text-center shadow-xl max-sm:hidden">
          <div className="flex flex-wrap justify-center px-2">
            <Link to="services-panel-1" offset={-100} smooth={true} duration={500}>
            <p className="flex px-4 py-2 text-slate-800">
              <CodeIcon className="h-6 pr-1 text-blue-600" /> Development 
            </p>
            </Link>
            <Link to="services-panel-2" offset={-150} smooth={true} duration={500}>
            <p className="flex px-4 py-2 text-slate-800">
              <PaperAirplaneIcon className="h-6 pr-1 text-blue-600" /> Performance Auditing
            </p>
            </Link>
            <Link to="services-panel-3" offset={-150} smooth={true} duration={500}>
            <p className="flex px-4 py-2 text-slate-800">
              <CameraIcon className="h-6 pr-1 text-blue-600" /> Designing & Branding
            </p>
            </Link>
            <Link to="services-panel-4" offset={-150} smooth={true} duration={500}>
            <p className="flex px-4 py-2 text-slate-800">
              <ServerIcon className="h-6 pr-1 text-blue-600" /> Web Hosting 
            </p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;

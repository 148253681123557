import {React} from 'react'

const About = () => {
    
  return (
    <div name='about' className='w-full mt-12'>
        <div className='max-w-[1240px] mx-auto'>
            <div className='text-center'>
                <h2 className='text-3xl sm:text-4xl font-bold text-blue-600'>About Valley Dev</h2>
                <p className='text-xl py-6 text-gray-500'>Located right in The Ottawa Valley, Our Team Includes a Front-End Developer, Photographer, Professional Designer &amp; Branding Specialist.</p>
            </div>

            <div className='grid md:grid-cols-3 gap-3 px-2 text-center'>
                <div className='border-2 border-gray-200 py-8 rounded-xl shadow-2xl'>
                    <p className='text-6xl font-bold text-blue-600'>10+</p>
                    <p className='text-2xl text-gray-500 mt-2'>Finished Projects</p>
                </div>
                <div className='border-2 border-gray-200 py-8 rounded-xl shadow-2xl'>
                    <p className='text-6xl font-bold text-blue-600'>8+</p>
                    <p className='text-2xl text-gray-500 mt-2'>Years Experience</p>
                </div>
                <div className='border-2 border-gray-200 py-8 rounded-xl shadow-2xl'>
                    <p className='text-6xl font-bold text-blue-600'>100%</p>
                    <p className='text-2xl text-gray-500 mt-2'>Guaranteed Satisfaction</p>
                </div>
            </div>
                <p className=' text-center text-xl p-6 text-gray-500'> Most of our sites are built 100% from scratch with HTML, CSS, JavaScript and libraries such as JQuery, React, Tailwindcss and developed and tested on Node.js and bundled to work on all modern browsers. We also use open Source plugins for added functionality and design.</p>
        </div>
    </div>
  )
}

export default About
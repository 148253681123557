import { hover } from "@testing-library/user-event/dist/hover";
import React from "react";
import { FaMailBulk } from "react-icons/fa";

const Contact = () => {
  return (
    <>
    <div name='contact' className='w-full h-screen bg-slate-900 flex justify-center items-center p-4'>
        <form method='POST' action="https://getform.io/f/1c0aa210-b871-4397-ac06-73d3e8193b00" className='flex flex-col max-w-[600px] w-full'>
            <div className='pb-8'>
                <p className='text-4xl font-bold inline border-b-4 border-blue-600 text-gray-300'>Contact Form</p>
                <p className='text-gray-300 py-4'>Feel free to reach out with any inquiries by sending me an email below & I will get back to you as soon as possible.</p>
            </div>
            <input className='bg-white p-2' required type="text" placeholder='Name' name='name' />
            <input className='my-4 p-2 bg-white' required type="email" placeholder='Email' name='email' />
            <input className='p-2 mb-4 bg-white' required type="text" placeholder='Subject' name='subject' />
            <textarea className='bg-white p-2' required name="message" rows="10" placeholder='Message'></textarea>
            
                <button className='w-full text-2xl py-4 my-4'><FaMailBulk
              size={30}
              style={{ display:"inline-block", marginRight:'5px'}}
            />Send E-Mail</button>
        </form>
    </div>

    </>
  );
};

export default Contact;

import React from 'react';
import { Link, animateScroll as scroll, } from 'react-scroll';

import { CheckIcon, TagIcon, XIcon } from '@heroicons/react/solid';

const Pricing = () => {
  return (
    <div name='pricing' className='w-full text-white '>
      <div className='w-full h-[800px] bg-slate-900 absolute mix-blend-overlay'></div>

      <div className='max-w-[1240px] mx-auto py-12'>

        <div className='text-center py-8 text-slate-300'>
          <h2 className='text-3xl uppercase'>Pricing</h2>
          <h3 className='text-5xl font-bold text-white py-8'>The right price for your budget.</h3>
          <p className='text-3xl'>
            All of our services are Effectively affordable. 
          </p>
        </div>

        <div className='grid lg:grid-cols-3'>

          <div className='bg-white text-slate-900 m-4 p-8 rounded-xl shadow-2xl relative'>
            <span className='uppercase px-3 py-1 bg-indigo-200 text-blue-800 font-semibold rounded-2xl text-sm'>Basic Websites</span>
            <div>
            <p className='text-3xl font-bold pt-4 flex uppercase'>Starting from</p>
              <p className='text-6xl font-bold py-4 flex'>$299<span className='text-xl text-slate-500 flex flex-col justify-end'></span></p>
            </div>
            <p className='text-2xl py-8 text-slate-500'>Perfect for Personal Portfolios &amp; Small Businesses looking to grow their presence online with a beautiful website</p>
            <div className='text-2xl'>
                <p className='flex py-4'><CheckIcon className='w-8 mr-5 text-green-600'  />Single Page Website </p>
                <p className='flex py-4'><CheckIcon className='w-8 mr-5 text-green-600'  />Mobile First &amp;  Optimized</p>
                    <p className='text-lg text-slate-500 ml-[52px]' > Meta Tags | Favicon | Social Sharing etc.</p>
                <p className='flex py-4'><CheckIcon className='w-8 mr-5 text-green-600'  />Basic SEO</p>
                <p className='flex py-4'><XIcon className='w-8 mr-5 text-red-600'  />E-Commerce Store Front</p>
                    <p className='text-lg text-slate-500 ml-[52px]' > Admin Dashboards | Customer Order Tracking &amp; Logins | Content Uploading.</p>
                <p title='This is an additional fee not including in the introductory price' className='flex py-4'><TagIcon className='w-8 mr-5 text-green-600'  /> Logo Design &amp; Branding</p>
                <p title='This is an additional fee not including in the introductory price' className='flex py-4'><TagIcon className='w-8 mr-5 text-green-600'  /> Hosting &amp; Domain Services</p>
                <Link to="contact" smooth={true} offset={-50} duration={500}><button className='w-full py-4 my-4'>Get Started</button></Link>
            </div>
          </div>
          <div className='bg-white text-slate-900 m-4 p-8 rounded-xl shadow-2xl relative'>
            <span className='uppercase px-3 py-1 bg-indigo-200 text-blue-800 font-semibold rounded-2xl text-sm'>Standard Websites</span>
            <div>
            <p className='text-3xl font-bold pt-4 flex uppercase'>Starting from</p>
              <p className='text-6xl font-bold py-4 flex'>$699<span className='text-xl text-slate-500 flex flex-col justify-end'></span></p>
            </div>
            <p className='text-2xl py-8 text-slate-500'>Suited for any business looking to provide customers with a full display of services &amp; product information</p>
            <div className='text-2xl'>
                <p className='flex py-4'><CheckIcon className='w-8 mr-5 text-green-600'  />Multi-Page Website </p>
                <p className='flex py-4'><CheckIcon className='w-8 mr-5 text-green-600'  />Mobile First &amp;  Optimized</p>
                    <p className='text-lg text-slate-500 ml-[52px]' > Meta Tags | Favicon | Social Sharing etc.</p>
                <p className='flex py-4'><CheckIcon className='w-8 mr-5 text-green-600'  />Premium SEO</p>
                <p className='flex py-4'><CheckIcon className='w-8 mr-5 text-green-600'  />Logo Design &amp; Branding</p>
                <p className='flex py-4'><XIcon className='w-8 mr-5 text-red-600'  />E-Commerce </p>
                    <p className='text-lg text-slate-500 ml-[52px]' > Admin Dashboards | Customer Order Tracking &amp; Logins | Content Uploading.</p>
                <p title='This is an additional fee not including in the introductory price' className='flex py-4'><TagIcon className='w-8 mr-5 text-green-600'  /> Hosting &amp; Domain Services</p>
                <div>
                    <Link to="contact" smooth={true} offset={-50} duration={500}><button className='w-full py-4 my-4'>Get Started</button></Link>
                </div>
            </div>
          </div>
          <div className='bg-white text-slate-900 m-4 p-8 rounded-xl shadow-2xl relative'>
            <span className='uppercase px-3 py-1 bg-indigo-200 text-blue-800 font-semibold rounded-2xl text-sm'>Premium Websites</span>
            <div>
            <p className='text-3xl font-bold pt-4 flex uppercase'>Starting from</p>
              <p className='text-6xl font-bold py-4 flex'>$1299<span className='text-xl text-slate-500 flex flex-col justify-end'></span></p>
            </div>
            <p className='text-2xl py-8 text-slate-500'>Fully Customizable Websites With E-Commerce, Order Tracking, Customer Logins &amp; Admin Dashboards</p>
            <div className='text-2xl'>
                <p className='flex py-4'><CheckIcon className='w-8 mr-5 text-green-600'  />Multi-Page Website </p>
                <p className='flex py-4'><CheckIcon className='w-8 mr-5 text-green-600'  />Mobile First &amp;  Optimized</p>
                    <p className='text-lg text-slate-500 ml-[52px]' > Meta Tags | Favicon | Social Sharing etc.</p>
                <p className='flex py-4'><CheckIcon className='w-8 mr-5 text-green-600'  />Premium SEO</p>
                <p className='flex py-4'><CheckIcon className='w-8 mr-5 text-green-600'  />Logo Design &amp; Branding</p>
                <p className='flex py-4'><CheckIcon className='w-8 mr-5 text-green-600'  />E-Commerce </p>
                    <p className='text-lg text-slate-500 ml-[52px]' > Admin Dashboards | Customer Order Tracking &amp; Logins | Content Uploading.</p>
                <p title='This is an additional fee not including in the introductory price' className='flex py-4'><CheckIcon className='w-8 mr-5 text-green-600'  /> Hosting & Domain Services</p>
                <Link to="contact" smooth={true} offset={-50} duration={500}><button className='w-full py-4 my-4'>Get Started</button></Link>
            </div>
          </div>
        </div>
            <p className='text-2xl text-center px-2 py-8 max-sm:text-xl text-slate-500'>For all other services and inquiries please feel free to send us a message. Each project is unique and requires different services modelled around each client's needs. Quotes are based on project time, complexity and other variables. Before any work takes place an in-depth relay of these services is presented to all clients as we guarantee full transparency in our work.</p>
      </div>
    </div>
  );
};

export default Pricing;
import React from 'react'

import {
    FaFacebook,
    FaInstagram,
} from 'react-icons/fa'

const Footer = () => {

const date = new Date();
let currentYear = date.getFullYear().toString();

  return (
    <div className='w-full bg-slate-900 text-gray-200 px-2'>

        <div className='flex flex-col max-w-[1240px] px-2 py-4 mx-auto justify-between sm:flex-row text-center'>
        <p className='py-4'>Copyright &copy; {currentYear}&nbsp; Valley Dev</p>
        <div className='flex justify-center gap-2 sm:w-[300px] pt-4 text-2xl'>
            <a aria-label="Link To Valley Dev Facebook Page" target={"_blank"} rel={'noreferrer'} href='https://www.facebook.com/ValleyDev.ca'><FaFacebook /></a>
            <a aria-label="Link To Valley Dev Instagram Page" target={"_blank"} rel={'noreferrer'} href='https://www.instagram.com/valley_dev/'><FaInstagram /></a>
        </div>
        </div>
    </div>
  )
}

export default Footer